<template>
  <div class="container">
    <!-- <div style="position: absolute; top: 65px; right: 20px;">
      <a-button type="primary" class="ML12" @click="submit">确认</a-button>
      <a-button class="ML12" @click="$router.back()">取消</a-button>
    </div> -->

    <DetailTop :businessResp="businessResp" :personalResp="personalResp" />

    <a-form-model ref="form" style="margin-top: 20px;" :model="formData" disabled :colon="false">
      <!--实名认证-->
      <template title="真实姓名" v-if="authType === '1'">
        <a-form-model-item label="真实姓名" prop="realName" style="width: 500px;">
          <a-input allowClear v-model="formData.realName" placeholder="请输入" disabled />
        </a-form-model-item>
        <div class="flex-start" style="width: 850px;margin-bottom: 15px;flex-wrap: wrap;">
          <a-form-model-item prop="handIdentityCard">
            <a-upload style="margin: 0 15px 15px 0" name="file" :multiple="false" :showUploadList="false" disabled>
              <img v-if="idCards.handIdentityCard" :src="`${$const.OssImagePrefix}/${idCards.handIdentityCard}`"
                class="upload-btn-02" />
              <a-button v-else class="upload-btn-02">点击上传手持身份证图片</a-button>
            </a-upload>
          </a-form-model-item>

          <a-form-model-item prop="frontIdentityCard">
            <a-upload style="margin: 0 15px 15px 0" name="file" :multiple="false" :showUploadList="false" disabled>
              <img v-if="idCards.frontIdentityCard" :src="`${$const.OssImagePrefix}/${idCards.frontIdentityCard}`"
                class="upload-btn-02" />
              <a-button v-else class="upload-btn-02">点击上传身份证正面图片</a-button>
            </a-upload>
          </a-form-model-item>

          <a-form-model-item prop="backIdentityCard">
            <a-upload style="margin: 0 15px 15px 0" name="file" :multiple="false" :showUploadList="false" disabled>
              <img v-if="idCards.backIdentityCard" :src="`${$const.OssImagePrefix}/${idCards.backIdentityCard}`"
                class="upload-btn-02" />
              <a-button v-else class="upload-btn-02">点击上传身份证反面图片</a-button>
            </a-upload>
          </a-form-model-item>
        </div>
      </template>
      <!-- <template title="简介认证" v-if="authType === '4'">
        <a-form-model-item label="认证简介" prop="shopBrief" style="width: 500px;">
          <a-textarea allowClear v-model="formData.shopBrief" placeholder="请输入" show-count :maxlength="200"
            :auto-size="{ minRows: 6 }" disabled />
        </a-form-model-item>
      </template> -->
       <template title="品牌故事" v-if="authType === '4'">
        <a-form-model-item label="品牌故事认证" prop="shopBrief" style="width: 500px;">
          <a-textarea allowClear v-model="formData.shopBrief" placeholder="品牌故事不超过2000字符,含标点和空格" show-count
            :maxlength="2000" :auto-size="{ minRows: 6 }" disabled/>
        </a-form-model-item>
      </template>
       <template title="主页名称认证" v-if="authType === '14'">
        <a-form-model-item label="主页名称认证" prop="shopName" style="width: 300px;">
           <a-input v-model="formData.shopName" placeholder="请输入主页名称" disabled />
        </a-form-model-item>
      </template>
      <template title="简介认证" v-if="authType === '15'">
        <a-form-model-item label="简介认证" prop="intro" style="width: 500px;">
          <a-textarea allowClear v-model="formData.intro" placeholder="简介不超过100字符,含标点和空格" show-count
            :maxlength="100" :auto-size="{ minRows: 6 }"  disabled />
        </a-form-model-item>
      </template>
      <template title="品牌认证" v-if="authType === '5'">
        <a-form-model-item label="品牌认证" prop="brandName" style="width: 500px">
          <a-input allowClear v-model="formData.brandName" placeholder="请输入商标名称" disabled />
        </a-form-model-item>
        <p><span>商标注册证</span></p>
        <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
          <img v-if="brandImages.brandRegistPic" :src="`${$const.OssImagePrefix}/${brandImages.brandRegistPic}`"
            class="upload-btn" style="margin-right:10px" />
          <img v-if="brandImages.brandLogoPic" :src="`${$const.OssImagePrefix}/${brandImages.brandLogoPic}`"
            class="upload-btn" />
        </div>
        <p><span>商标授权证</span></p>
        <div class="flex-start" style="width: 900px;margin-bottom: 15px;flex-wrap: wrap;">
          <img v-if="brandImages.brandAuthorizePic" :src="`${$const.OssImagePrefix}/${brandImages.brandAuthorizePic}`"
            class="upload-btn" />
        </div>
      </template>
      <template title="经营地址认证" v-if="authType === '6'">
        <a-form-model-item label="经营地址认证" prop="shopAdressDetail" style="width: 500px">
          <a-input allowClear v-model="formData.shopAdressDetail" placeholder="请输入经营地址" disabled />
        </a-form-model-item>
        <div class="justify-start">
          <p style="margin-right: 15px;"><span>工作时间</span></p>
          <div class="flex-column">
            <div style="display:flex;width:250px">
              <a-select ref="select" v-model="formData.workDaysBegin" disabled style="flex:1" @focus="focus">
                <a-select-option value="周一">周一</a-select-option>
                <a-select-option value="周二">周二</a-select-option>
                <a-select-option value="周三">周三</a-select-option>
                <a-select-option value="周四">周四</a-select-option>
                <a-select-option value="周五">周五</a-select-option>
                <a-select-option value="周六">周六</a-select-option>
                <a-select-option value="周日">周日</a-select-option>
              </a-select>
              <div style="width: 30px;text-align: center">~</div>
              <a-select ref="select" v-model="formData.workDaysEnd" disabled style="flex:1" @focus="focus">
                <a-select-option value="周一">周一</a-select-option>
                <a-select-option value="周二">周二</a-select-option>
                <a-select-option value="周三">周三</a-select-option>
                <a-select-option value="周四">周四</a-select-option>
                <a-select-option value="周五">周五</a-select-option>
                <a-select-option value="周六">周六</a-select-option>
                <a-select-option value="周日">周日</a-select-option>
              </a-select>
            </div>
            <div style="display:flex;width:250px;margin-top: 10px;">
              <a-time-picker style="flex:1:" format="HH:mm" disabled valueFormat="HH:mm" :minuteStep="1"
                v-model="formData.workTimeBegin" />
              <div style="width: 30px;text-align: center">~</div>
              <a-time-picker style="flex:1:" format="HH:mm" disabled valueFormat="HH:mm" :minuteStep="1"
                v-model="formData.workTimeEnd" />
            </div>
          </div>
        </div>
        <p><span>选择地图上的定位</span></p>
        <div class="flex-start" style="width: 900px;margin-bottom: 15px;flex-wrap: wrap;">
          <div>
            <txMap ref="txMap" v-if="addrobj&&addrobj.lat" :lng="addrobj.lng||0" :lat="addrobj.lat||0" :isclick="false">
            </txMap>
          </div>
        </div>
        <p><span>上传经营地址签约合同图</span></p>
        <div class="flex-start" style="width: 900px;margin-bottom: 15px;flex-wrap: wrap;">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <!-- <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <a-input
                style="width: 180px; margin-right: 15px"
                allowClear
                v-model="authItem.title"
                placeholder="请输入"
                disabled
              />
            </div> -->
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template title="办公环境认证" v-if="authType === '9'">
        <a-form-model-item label="办公环境认证">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <a-input style="width: 280px; margin-right: 15px;display:none" allowClear v-model="authItem.title"
                placeholder="请输入" disabled />
            </div>
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
              </div>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <template title="身份认证" v-if="authType === '2'">
        <a-form-model-item label="身份认证">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <a-input style="width: 500px; margin-right: 15px" allowClear v-model="authItem.title"
                placeholder="例:米兰国际设计师,擅长原作/国风" disabled />
            </div>
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img, idx) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
                <img src="@/assets/delete.png" class="img-delete" @click="handleRemoveImage(index, idx)" />
              </div>
              <!-- <a-upload
                style="margin: 0 15px 15px 0"
                name="file"
                :multiple="false"
                :before-upload="handleBeforeUpload"
                @change="(e) => handleAuthImage(e, index)"
                :customRequest="handleCustomUpload"
                :showUploadList="false"
                v-if="!authItem.imgs || authItem.imgs.length < 9"
              >
                <a-button class="upload-btn-02">认证图片</a-button>
              </a-upload> -->
            </div>
          </div>
        </a-form-model-item>
      </template>
      <template title="经历认证" v-if="authType === '12'">
        <a-form-model-item label="经历认证" prop="shopName">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <a-input style="width: 290px; margin-right: 15px" allowClear v-model="authItem.timePeriod"
                placeholder="请输入" value="2023-2024" disabled />
              <a-input style="width: 220px" allowClear v-model="authItem.title" placeholder="请输入" value="设计机构开业"
                disabled />
            </div>
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
              </div>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <template title="获奖认证" v-if="authType === '13'">
        <a-form-model-item label="获奖认证" prop="shopName">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <a-input style="width: 290px; margin-right: 15px" allowClear v-model="authItem.title" placeholder="请输入"
                value="2023-2024" disabled />
            </div>
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
              </div>
            </div>
          </div>
        </a-form-model-item>
        <a-button type="primary" class="ML12" @click="handleAddAuth">新增获奖</a-button>
      </template>
      <template title="展览认证" v-if="authType === '11'">
        <a-form-model-item label="展览认证" prop="shopName">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <a-input style="width: 180px; margin-right: 15px" allowClear v-model="authItem.timePeriod"
                placeholder="请输入" value="2023-2024" disabled />
              <a-input style="width: 280px;" allowClear v-model="authItem.exhibitionHall" placeholder="展览空间名称" value=""
                disabled />
              <a-input style="width: 270px; margin-left: 10px;" allowClear v-model="authItem.adress"
                placeholder="请输入展览地址" value="" disabled />
            </div>
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
              </div>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <template title="店铺环境认证" v-if="authType === '10'">
        <a-form-model-item label="店铺环境认证">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <a-input style="width: 300px; margin-right: 15px;display:none;" allowClear v-model="authItem.title"
                placeholder="请输入" disabled />
            </div>
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
              </div>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <a-form-model-item label="状态" prop="state">
        <a-radio-group v-model="formData.state">
          <a-radio :value="0">认证待审核</a-radio>
          <a-radio :value="1">已认证</a-radio>
          <a-radio :value="2">未通过认证</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
  import DetailTop from './audit-top.vue'
  import * as Settle from '@/api/user/settle'
  import txMap from "../../components/txmap";

  export default {
    components: {
      DetailTop,
      txMap
    },
    name: 'insert',
    data() {
      return {
        authType: 1,
        userType: 1,
        fileUploadLimit: 0,
        id: '',
        userInfotop: {},
        userInfo: {},
        form: {
          uploadFileList: []
        },
        formData: {
          setlleType: 0,
           intro:"",// 简介认证
          shopBrief: '',// 品牌故事
          shopName: undefined,
          enterpriseName: undefined,
          manageType: undefined,
          platform: 0,
          state: 0,
          shopAdress: "",
          shopAdressDetail: "",
          workDaysBegin: "周一",
          workDaysEnd: "周一",
          workTimeBegin: "",
          workTimeEnd: "",
        },
        idCards: {
          handIdentityCard: "",
          frontIdentityCard: "",
          backIdentityCard: "",
        },
        brandImages: {
          brandLogoPic: "",
          brandRegistPic: "",
          brandAuthorizePic: "",
        },
        authFormData: [{
          timePeriod: '',
          title: '',
          imgs: []
        }],
        businessResp: {},
        personalResp: {},
        addrobj: {}
      };
    },
    created() {
      this.id = this.$route.query.id;
      this.userId = this.$route.query.userId;
      this.authType = this.$route.query.authType
      this.userType = this.$route.query.userType
    },
    mounted() {
      this.queryDetailById()
    },
    methods: {
      queryDetailById() {
        Settle.applyDetail({
            applyId: this.id,
            businessType: Number(this.userType),
          })
          .then(res => {
            const data = res.data || {}
            this.formData = data || {}
            this.userInfo = data || {}
            this.businessResp = res.data.businessInfoResp || {}
            this.personalResp = res.data.personalResp || {}
            console.log('this.userInfo.businessResp', this.businessResp)
            if (this.authType === '1') {
              this.idCards = data
            } else if (this.authType === '2') {
              this.authFormData[0].imgs = data.pics
              this.authFormData[0].title = data.identityName
            } else if (this.authType === "5") {
              this.formData.brandName = data.brandName
              this.brandImages.brandLogoPic = data.brandLogoPic
              this.brandImages.brandRegistPic = data.brandRegistPic
              this.brandImages.brandAuthorizePic = data.brandAuthorizePic
            } else if (this.authType === "6") {
              this.formData.shopAdress = data.shopAdress
              if (data.shopAdress) {
                this.addrobj = JSON.parse(data.shopAdress);
              }
              this.formData.shopAdressDetail = data.shopAdressDetail
              this.formData.workDaysBegin = data.workDaysBegin
              this.formData.workDaysEnd = data.workDaysEnd
              this.formData.workTimeBegin = data.workTimeBegin
              this.formData.workTimeBegin = data.workTimeEnd
              this.authFormData[0].imgs = data.pics
            } else if (this.authType === "9") {
              this.authFormData[0].imgs = data.pics
              this.authFormData[0].title = data.title
            } else if (this.authType === "10") {
              this.authFormData[0].imgs = data.pics
              this.authFormData[0].title = data.title
            } else if (this.authType == 11) {
              let _data = data.exhibitionContentItem
              this.exMap(_data)
            } else if (this.authType == 12) {
              let _data = data.experienceContentItem
              this.exMap(_data)
            } else if (this.authType == 13) {
              let _data = data.awardContentItem
              this.exMap(_data)
            }
            this.formData.state = data.state
          })
          .finally(() => {
            this.loading = false
          })
      },
      exMap(data) {
        this.authFormData = []
        data.map(item => {
          return this.authFormData.push({
            timePeriod: item.timePeriod,
            title: item.title,
            exhibitionHall: item.exhibitionHall,
            adress: item.adress,
            imgs: item.pics,
          })
        })
      },
      focus() {},
      handleMap(res) {
        this.formData.shopAdress = JSON.stringify(res.addr)
      }
    }
  }
</script>

<style scoped lang="less">
  .container {
    padding: 20px;
    background: #ffffff;

    .label {
      width: 100px;
    }
  }

  .flex-start {
    display: flex;
    justify-content: flex-start;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  .upload-btn {
    width: 411px;
    height: 244px;
    font-weight: bold;
    font-size: 20px;
    background: #F2F2F2;
  }

  .upload-btn-02 {
    width: 285px;
    height: 244px;
    font-weight: bold;
    font-size: 20px;
    background: #F2F2F2;
  }

  .justify-start {
    display: flex;
    justify-content: flex-start;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  /deep/ .ant-form-item-required {
    font-size: 18px;
    font-weight: bold;
  }

  .img-wrap {
    position: relative;
  }

  .img-delete {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
</style>
